  .paginator {
    width: 75%;
    max-width: 1000px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    z-index: 1;
  }

  .paginator .navigator {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
  }

  .paginator button{ 
    font-weight: 400 ;
    background-color: transparent;
    border: none;
    color: rgb(206, 202, 202);
    -webkit-user-select: none;
    user-select: none;
  }

  .paginator li{
    margin-left: 6px;
    background-color: #3f3e3e;
    box-shadow: 6px 6px 4px -1px rgba(0, 0, 0, 0.4);
    border-radius: 4px;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 2px;
    padding-bottom: 2px;
  }

  .tablePagination-div{
    width: 100%;
    display: none;
    overflow: visible;

    .MuiTablePagination-toolbar {
      padding: 0;
      margin: 0;
      min-height: 0;
      margin-top: 10px;
      height: 30px !important;
      overflow: visible;
    }

    .MuiTablePagination-caption{
      color: white;
    }
  }

  @media (max-width: 1000px) {
    .paginator {
      width: 100%;
    }
  }

  @media (max-width: 900px) {
    .paginator {
      padding: 10px;
      padding-top: 0px;
    }

    .paginator .navigator{
      width: 100%;
    }
  }

  @media (max-width: 600px) {
    .navigator{
      display: none !important;
    }

    .paginator{
      padding-bottom: 0;
      border-radius: 4px;
      overflow: visible;
    }

    .tablePagination-div{
      display: flex;
      justify-content: flex-end;
      width: 100%;
      background-color: #333333;
      border-radius: 4px;
      box-shadow: 6px 6px 4px -1px rgba(0, 0, 0, 0.4);
      max-width: 1000px;
      height: 50px;
    }
  }

  .ellipsis-input input{
    background-color: transparent;
    color: rgb(206, 203, 203);
    -moz-appearance: textfield !important;
    appearance: textfield !important;
    border: none;
    box-sizing: none;
    text-align: center;
    vertical-align: middle;
    border-bottom: 1px solid rgb(206, 203, 203);
    border-radius: 0;
    outline: none;
  }
  
  .tablePagination-manual input {
    width: 60px;
    margin-top: -5px;
    margin-left: 10px;
    margin-right: 10px;
    background-color: transparent;
    color: white;
    font-size: 16px;
    text-align: center;
    -moz-appearance: textfield !important;
    appearance: textfield !important;
    border: none;
    box-sizing: none;
    vertical-align: middle;
    border-bottom: 1px solid white;
    border-radius: 0;/* Adjust the color as needed */
    outline: none; /* Adjust the color as needed */
  }

  .tablePagination-manual input:focus {
    border-color: white;
    border-radius: 0;/* Adjust the color as needed */
  }

  .tablePagination-manual{
    display: flex;
    width: 200px;
  }

  .tablePagination-manual span{
    padding-left: 8px;
    padding-right: 8px;
  }

.hidden-input {
  -moz-appearance: textfield;
  appearance: textfield;
  width: 50px; /* Adjust the width as needed */
}

/* Chrome, Safari, Edge */
.hidden-input::-webkit-inner-spin-button,
.hidden-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.hidden-input[type=number] {
  -moz-appearance: textfield;
}