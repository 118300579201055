.post_page,
.post_not_found {
  margin: auto;
  margin-bottom: 100px;
  display: flex;
  flex-direction: row;
  width: 100%;
}
.post_not_found {
  text-align: center;
}

.post_page_left_bar{
  min-width: 290px;
  height: 100%;
}

.post_page_right{
  z-index: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.post_page_body{
  width: 95%;
  max-width: 1000px;
}

@media (max-width: 883px){
  .post_page_left_bar {
    display: none;
  }

  .post_list_post_controls{
    display: unset !important;
  }

  .comment_option_bottom{
    display: unset !important;
  }
}

.nice_h1 {
  padding: 15px;
  color: white;
  font-size: 22px;
  font-weight: 600;
  background-color: #e69344;
  margin: 10px 0px 10px 0px;
  text-align: center;
  position: relative;
}

.post_page_contents {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background-color: #2c2b2bcd;
  backdrop-filter: blur(8px);
   border-radius: 4px;

  hr {
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    height: 4px;
    background-color: #e69344;
    border-color: transparent;
    border-radius: 2px;
  }
}

.post_page_contents > * {
  margin: 0px 0px 5px;
}

.post_page_upvotes {
  width: 120px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: rgb(247, 241, 241);
  outline: 1px dotted black;
  height: 50px;
  padding: 5px;
}
.post_page_upvotes p {
  font-size: 12px;
  line-height: 1.5;
  font-weight: 600;
}

.post_page_details {
  font-size: 12px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  justify-content: space-between;
  padding-right: 8px;
  .post_contents {
    flex-grow: 1;
    width: 0 ;
  }

  @media (max-width: 900px){
    .post_contents {
      max-width: 100%;
    }  
  }

  .post_body_controls_div {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
  }
}

.post_page_details_top {
  display: flex;
  align-items: flex-end;
  justify-items: end;
}

.post_page_details_top_status{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-grow: 2;
}

.post_page_categories {
  padding: 0px 0px 10px 0px;
  display: flex;
  justify-content: flex-start;
}

.border_bottom {
  border-bottom: 1px solid #bdc3c7;
  padding-bottom: 10px;
}
.post_page_header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  color: white;
  height: 80px;

  .comments_number {
    position: relative;
    padding-top: 16px;
    margin-right: -8px;
    display: flex;
    align-items: center;
    
    span {
      margin-bottom: 5px;
      margin-right: -11px;
    }

    svg {
      margin-left: 8px;
    }
  }

  .post_contents h1{
    font-size: 20px;
  }

  .post_upvotes{
    max-width: 90px;
  }

  .version_number{
    font-size: 19px;
    color: white;
  }

  .post_list_post_controls{
    position: relative;
    margin-top: -12px;
    margin-right: -18px;
    display: none;
  }
}

.comment_option_bottom{
  display: none;
}

.post_page_details p {
  color: rgb(116, 116, 248);
  text-decoration: underline;
  cursor: pointer;
  display: inline-block;
}

.post_page_details span {
  margin-right: 5px;
}
.post_page_controls {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 10px;
  height: 40px;
}
.post_page_controls button {
  border: 0;
  background: #2c3e50 none center no-repeat;
  color: #fff;
  width: auto;
  padding: 0 10px 0 30px;
  background-position: 8px center;
  line-height: 32px;
  cursor: pointer;
  float: left;
  margin: 0 5px 0 0;
  background-image: url("https://satisfactoryweb.blob.core.windows.net/public/qa_site/answer-white.png");
}
.post_page_controls button:hover {
  background-color: #2ecc71;
}

.red {
  background-color: #e74c3c;
}
.blue {
  background-color: #3498db;
}

.post_comments {
  margin: 10px 0px 10px 0px;
  background-color: #2c2b2bcd;
  backdrop-filter: blur(8px);
   color: white;
  padding: 10px;
  padding-top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.post_comments_header {
  margin-left: -10px;
  margin-right: -10px;
  margin-bottom: 10px;
  padding-left: 20px;
  height: 60px;
  background-color: rgb(74, 74, 74);
  display: flex;
  align-items: center;
}

.post_comments_header > h1 {
  font-weight: 800;
  font-size: 19px;  

}
.post_comment,
.post_comment_answer {
  margin-bottom: 10px;
  color: rgb(179, 178, 178);
  animation: expand 0.2s ease-in-out;
  display: flex;
  justify-content: flex-start;
}
.post_comment_answer {
  border: 3px solid #2ecc71;
}

.post_comment_points{
  color: white;
}

@keyframes expand {
  from {
    opacity: 0;
    background: #5470b0;
  }
}
.marked_as_answer {
  font-weight: 600;
}
.post_comment_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.post_comment_header strong {
  margin-right: 3px;
}

.post_comment_text_editor textarea {
  height: 100px;
  width: 100%;
  resize: none;
}

.post_comment_text_editor_preview > .post_comment_text {
  width: 100%;
  overflow: auto;
  border: 1px solid rgba(85, 84, 84, 0.233);
}

.post_comment_text_editor > .post_comment_text {
  height: 100px;
  width: 100%;
  overflow: auto;
  border: 1px solid rgba(85, 84, 84, 0.233);
}
.parent {
  margin-bottom: 0px;
}
.post_comment_info {
  display: flex;
  align-items: center;
}
.post_comment_author {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: 14px;
  color: grey;
}
.post_comment_author a {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.post_comment_author > *,
.post_comment_author > a > p {
  margin-right: 5px;
}
.post_page_created{
  padding-top: 5px;
}

.post_page_edited{
  padding-top: 4px;
}

.post_comment_edited,
.post_page_edited {
  font-size: 11px;
  text-align: left;
  font-weight: 400;
  color: grey;
}
.post_page_duplicates {
  background-color: #333333de;
  color: white;
}

.post_page_duplicates > div {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  align-items: center;
  height: 30px;
  padding: 10px;
}
.post_page_duplicates ul {
  list-style: inside;
  margin-left: 10px;
  padding: 10px;
}

.post_page_duplicates h1 {
  font-weight: 600;
}
.post_page_duplicate {
  margin-bottom: 5px;
}
.post_comment_info button {
  margin-right: 5px;
  margin-bottom: 5px;
}
.post_flag {
  cursor: pointer;
}
.comment_flag {
  margin-right: 10px;
  cursor: pointer;
}

.post_page_top_controls {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.post_page_top_controls svg,
.post_page_top_controls div {
  margin-right: 5px;
}
.post_page_top_controls svg {
  cursor: pointer;
}
.post_page_top_bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.post_page_controls_right {
  display: flex;
}
.post_page_controls_right > * {
  margin-right: 10px;
}
.post_comment_body {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-left: 10px;
  word-break: break-word;
}

.post_comment_body_header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.post_comment_body hr {
  width: 100%;
}

.post_comment_controls {
  display: flex;
  justify-content: space-evenly;
}

.post_comment_controls > * {
  margin-right: 5px;
}

@media (max-width: 550px) {
  .post_comment_answer .upvote_display,
  .post_comment .upvote_display {
    flex-direction: column;
    align-items: flex-end;
    width: 80px;
    height: 50px;
    
    margin-right: -10px;
  }

  .post_comment_author{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .post_comment_points{
    margin-top: 2px;
  }

  .post_comment_answer .upvote_display > div,
  .post_comment .upvote_display > div {
    height: 25px;
  }

  .version_number{
    font-size: 11px !important;
    color: white;
  }
}
@media (max-width: 350px) {
  .post_comment_points {
    display: none;
  }
}

.dev_logo {
  max-width: 20px;
}

.sub_comments {
  margin-top: 5px;
}

.post_comment_text {
  line-height: 1.3;
  color: white;
}

.post_comment_text p {
}
.post_comment_text,
.comment_box code {
  line-height: 1.2;
  font-size: 14px;
}
.post_comment_text li,
.comment_box li {

}
.post_comment_text strong,
.comment_box strong {
  font-weight: 600;
}

.post_comment_text h1,
.comment_box h1 {
  font-weight: 800;
  font-size: 24px;
  letter-spacing: 2px;
  margin-bottom: 20px;
}

.post_comment_text h2,
.comment_box h2 {
  font-weight: 600;
  font-size: 18px;
  margin-top: 10px;
}
.post_comment_text h3,
.comment_box h3 {
  font-weight: 600;
  font-size: 16px;
  margin-top: 10px;
}

.post_comment_text code,
.comment_box code {
  background-color: rgba(82, 83, 85, 0.486);
  border-radius: 3px;
  font-size: 85%;
  margin: 5px 5px 5px 0px;
  padding: 0.2em 0.4em;
  width: 100%;
  display: block;
}
