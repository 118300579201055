.sidenav {
  height: 100%;
  width: 290px;
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #4a4a4a5c;
  overflow-x: hidden;
  margin-top: 0;
  box-shadow: 4px 4px 4px -1px rgba(0, 0, 0, 0.4),
  1px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.sidenav .sidenav-container{
  flex: 1;
  display: flex;
  flex-direction: column;
}

.sidenav .sidenav-flexible-child{
  flex: 1;
  display: flex;
  flex-direction: column;
}

.sidenav-fixed-height-child-not-logged{
  color: white;
  font-weight: 300;
  font-size: 17px !important; 
  padding: 10px !important;
}

/* unread_messages */

.sidenav-fixed-height-child .makeStyles-root-1  .unread_messages .logo_notifications{
  height: 25px !important;
  display: unset !important;
  margin-top: -1px;
  padding: 0;
}

.sidenav-fixed-height-child .makeStyles-root-1  .unread_messages span{
  margin-top: -5px;
  font-size: 12px;
}

.sidenav .sidenav-fixed-height-child{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 60px;
  margin: 10px;

  p {
    padding-left: 10px;
    font-size: 17px;
    font-weight: 800;
    color: white;
  }

  .nav_active {
    display: none;
  }

  .MuiButton-root{
    font-weight: 400 ;
    color: white;
  }
}

.sidenav .nav_block_sidenav{
  margin-bottom: 20px;
  width: 290px;
}


.sidenav .nav_block_sidenav > img {
  margin-left: -14px;
}

.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
}

.sidenav .basic-multi-select {
  padding-left: 18px;
  margin-bottom: 18px;
}

.sidenav .MuiFormControlLabel-root{
  margin-top: -10px;
  padding-top: 0px;
  padding-left: 18px;
  color: white;
}

.sidenav .post_controls_lower input[type="text"] {
  width: 250px;
  margin-bottom: 18px;
  background-color: #3f3e3e5c;
  color: white;
  border-width: 1px;
  border-color: hsl(0,0%,80%);
  border-radius: 4px;
  font-size: 18px;
  line-height: 27px;
  margin-left: 18px;
  box-shadow: 6px 6px 4px -1px rgba(0, 0, 0, 0.4);

}

.sidenav .post_searchbar svg {
  position: absolute;
  top: 3.5px;
  left: 24px;
  font-size: 15px;
}

.sidenav .post_searchbar input {
  text-indent: 0px;
  padding-left: 10px;
  padding-right: 30px;
}


.sidenav .post_searchbar svg {
  position: absolute;
  top: 3.5px;
  left: 240px;
  font-size: 15px;
}

.sidenav .MuiCheckbox-root{
  color: white;
}

.sidenav .MuiCheckbox-hover{
  color: white;
}

.sidenav .MuiIconButton-colorSecondary{
  color: white;
}

.sidenav .MuiIconButton-colorSecondary:hover{
  background-color: rgba(255, 255, 255, 0.05);
}

.sidenav .MuiCheckbox-colorSecondary.Mui-checked:hover{
  background-color: rgba(255, 255, 255, 0.05);
}

.sidenav .MuiCheckbox-colorSecondary.Mui-checked{
  color: white;
}

.sidenav .MuiCheckbox-colorSecondary:hover{
  color: rgba(255, 255, 255, 0.8)
}

.control_text_selected:hover,
.control_text_admin:hover,
.control_text:hover{
  background-color: #bebebe5c;
  margin-bottom: 2px;
}

.sidenav .control_text p,
.sidenav .control_text_admin p,
.sidenav .control_text_disabled p,
.sidenav .control_text_selected p{
  color: aliceblue;
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 700;
  cursor: pointer;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.control_text_selected,
.control_text_selected p{
  background-color: #bebebe38;
  font-weight: 900 !important;
}

.sidenav .control_text_admin p{
  color: orange;
}

.sidenav .control_text_disabled p{
  cursor: not-allowed;
  color: gray;
}

.sidenav .control_text_selected svg,
.sidenav .control_text_admin svg,
.sidenav .control_text_disabled svg,
.sidenav .control_text svg{
  position: absolute;
  right: 10px;
  font-size: 20px;
}

.sidenav hr{
  width: 90%;
}