
.comment_panel {
    margin: 0;
    transition: all 0.15s ease-in-out;
    background-color: #595858;
    color: white;
    width: 100%;
    height: 100%;
  }
  .comment_panel > div {
    padding: 20px;
    height: 100%;
    padding-bottom: 1px;
  }
  .comment_buttons button {
    margin-right: 10px;
    background-color: #e67e22
  }

  .comment_buttons button:hover {
    background-color: #c36c1f
  }

  .comment_panel > div > * {
    margin-bottom: 15px;
  }
  .comment_panel textarea {
    height: 250px;
    width: 100%;
    resize: none;
  }

  .comment_panel .ql-toolbar.ql-snow{
    margin-bottom: 10px;
    padding: 5px;
    border: none !important;
    position: absolute;
    bottom: 0;
  }
  .comment_panel .post_comment_text {
    height: 150px;
    width: 100%;
    overflow: auto;
    border: 1px solid rgba(85, 84, 84, 0.233);
  }
  .comment_panel_rich_content{
    height: auto !important;
    max-height: 300px !important;
  }
  .comment_panel_descriptor_h1 {
    font-size: 20px;
    font-weight: 800;
  }
  .comment_panel_descriptor_p {
    font-size: 13px;
    font-weight: 600;
    color: white;
  }
  .comment_panel_descriptor_div{
    padding: 10px;
    background-color: #464646;
  }

  .comment_panel .quill,
  .comment_panel_descriptor_textarea {
    background-color: #464646;
    padding: 10px;
    color: white;
  }

  .comment_panel .ql-editor,
  .comment_panel_descriptor_textarea::-webkit-input-placeholder {
    color: whitesmoke;
    height: 235px;
  }

  .commment_window_pop_up{
    padding-bottom: 10px; 
    max-width: 800px;
    max-height: 100%;
  }

  .title_comment_pop_up{
    flex-grow: 2;
  }
  
  .comment_window_pop_up_title{
    height: 45px;
    display: flex;
    align-items: center;
    font-size: 20px;
    color: white;
    font-weight: 700;
    background-color: #464646;
  }

  .logo_close{
    font-size: 30px !important;
  }

  .logo_close_div{
    background-color: rgba(255, 255, 255, 0.304);
    height: 100%;
    width: 82px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .logo_close_div:hover {
    background-color: rgba(184, 184, 184, 0.304);
  }

  .comment_buttons{
    flex-shrink: 0;
  }


  .comment_panel_descriptor_formcontrol{
    width: 100%;
    display: flex;
  }

  .comment_panel_descriptor_formcontrol label {
    width: 100%;
  }

  .comment_panel_descriptor_formcontrol span {
    font-size: 16px;
  }

  @media (min-width: 997px) {
    .commment_window_pop_up{
      width: 70%;
    }
  }

  @media (min-width: 710px) {
    .comment_bottom{
      display: flex;
    }

    .comment_panel_descriptor_formcontrol{
      justify-content: flex-end;
    }
  }

  @media (max-width: 710px){
    .commment_window_pop_up{
      width: 100% !important;
    }
  }
  
  @media (max-width: 600px){
    .comment_buttons button {
      max-width: 123px;
      font-size: 10.5px;
    }

    .title_comment_pop_up{
      font-size: 17px;
    }
  }