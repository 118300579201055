.post_dashboard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 1000px;
  width: 98%;
  margin: auto;
  line-height: 1.5;
  height: 100%;
}

.post_dashboard > h1 {
  background-color: #e69344;
  padding: 10px;
  color: white;
  font-weight: 800;
  font-size: 20px;
  letter-spacing: 1.5px;
  margin: 5px 0px 5px 0px;
}

.post_dashboard_main_modal,
.post_dashboard_main {
  padding: 20px;
  padding-bottom: 0;
  padding-top: 15px;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  color: white;
  background-color: #2c2b2bcd;
  backdrop-filter: blur(8px);

  .post_main_question{
    background-color: #3f3e3e !important;
  }

  ::placeholder {
    color: rgb(218, 218, 218);
  }
  
  ::-ms-input-placeholder { 
    color: rgb(218, 218, 218);
  }
}

.post_dashboard_main_modal{
  background-color: #595858;

  .post_main_categories,
  .post_main_content{
    background-color: #3f3e3e;
  }
}

.post_dashboard_main_modal .comment_panel_descriptor_div,
.post_dashboard_main .comment_panel_descriptor_div{
  display: flex;
  flex-direction: row;
  background-color: transparent;
  padding: 0;
  align-items: center;

  p{
    flex-grow: 2;
  }

  .post_panel_descriptor_p{
    width: 100%;
    background-color: #3f3e3e;
    padding: 15px;
  }


  .post_main_version {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #3f3e3e;
    padding: 5px;
    padding-right: 10px;
    padding-left: 10px;
    margin-left: 10px;
    min-width: 275px;
    align-self: self-start;
  }
}


.submit_post_editor_modal{
  margin-left: -10px;
  display: flex;
  justify-content: flex-start;
}

.submit_post_editor_modal button {
  background-color: #e67e22;
  margin-right: 16px;
}

.submit_post_editor_modal button:hover,
.submit_post_editor button:hover {
  background-color: #c36c1f
}

.post_dashboard_main_modal > *,
.post_dashboard_main > * {
  padding: 5px 10px 5px 10px;
  margin-bottom: 5px;
  /* background-color: #333333; */
}

.post_brief {
  font-size: 14px;
}

.post_brief_inner{
  font-size: 14px;
  text-align: justify;
  display: none;
  background-color: #3f3e3e;
  padding: 10px;
}

.disclaimer_full {
  display: none;
}

.post_panel_descriptor_p {
  width: 100%;
  font-size: 13px;
  font-weight: 600;
  color: white;
}

.nice_textbox {
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
  font-family: "Ubuntu", "Helvetica", "Arial", "FreeSans", sans-serif;
  -webkit-transition: all 0.3s;
  transition: all 0.3s ease-in-out;
  padding: 5px;
  box-sizing: border-box;
  font-size: 14px;
  background-color: #3f3e3e;
  border: 1px solid #333333;
  color: white;
}

.nice_textbox:focus {
  outline: 1px solid #3498db;
  outline-offset: 0px;
}
.nice_textbox[disabled] {
  color: #777776;
  background-image: none;
  background-color: rgb(233, 227, 227);
}

.post_h2 {
  margin-bottom: 5px;
  font-weight: 600;
  font-size: 18px;
}

.post_patch_row .MuiCheckbox-root,
.post_second_row .MuiCheckbox-root{
  color: white;
}

.post_patch_row .MuiCheckbox-root,
.post_second_row .MuiCheckbox-hover{
  color: white;
}

.post_patch_row .MuiCheckbox-root,
.post_second_row .MuiIconButton-colorSecondary{
  color: white;
}

.post_patch_row .MuiCheckbox-root,
.post_second_row .MuiIconButton-colorSecondary:hover{
  background-color: rgba(255, 255, 255, 0.05);
}

.post_patch_row .MuiCheckbox-root,
.post_second_row .MuiCheckbox-colorSecondary.Mui-checked:hover{
  background-color: rgba(255, 255, 255, 0.05);
}

.post_patch_row .MuiCheckbox-root,
.post_second_row .MuiCheckbox-colorSecondary.Mui-checked{
  color: white;
}

.post_patch_row .MuiCheckbox-root,
.post_second_row .MuiCheckbox-colorSecondary:hover{
  color: rgba(255, 255, 255, 0.8)
}

.post_second_row {
  .post_main_version {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #3f3e3e;
    margin-right: -10px;
    margin-left: 10px;
    padding-right: 10px;

    .basic-multi-select{
      width: 230px;
    }
  }

  .post_main_question{
    flex-grow: 2;
    background-color: #6b6b6b34;
    margin-left: -10px
  }
}

.post_main_categories,
.post_main_content{
  background-color: #6b6b6b34;
}


.post_main_status_label,
.post_main_version_label{
  font-size: 12px;
  margin: 5px;
  margin-left: 9px;
  display: none;
}


.nice_textbox_post{
  width: 100%;
  font-family: "Ubuntu", "Helvetica", "Arial", "FreeSans", sans-serif;
  padding: 10px;
  font-size: 13px;
  font-weight: 600;
  color: rgb(218, 218, 218);
  border-radius: 4px;
  background-color: transparent;
  border-color: transparent;
}

.nice_textbox_post:hover::placeholder,
.nice_textbox_post:focus::placeholder,
.nice_textbox_post:active::placeholder {
  color: #5f5f5f;
}


 .nice_textbox_post:focus,
 .nice_textbox_post:active {
  outline: #333333;
  outline-width: 1px;
  outline-color: #ffffff0a;
}


.nice_textbox_post:-webkit-autofill,
.nice_textbox_post:-webkit-autofill:hover, 
.nice_textbox_post:-webkit-autofill:focus, 
.nice_textbox_post:-webkit-autofill:active{
  background-color: #3f3e3e !important;
  border-color: transparent !important;
}

.nice_textbox_post:-webkit-autofill,
.nice_textbox_post:-webkit-autofill:hover, 
.nice_textbox_post:-webkit-autofill:focus, 
.nice_textbox_post:-webkit-autofill:active{
-webkit-background-clip: text;
-webkit-text-fill-color: rgb(218, 218, 218);
background-color: #3f3e3e !important;

}

.searched_categories {
  display: flex;
  justify-content: flex-start;
  margin: 10px 0px 10px 0px;
}
.searched_categories .post_category,
.selected_cats .post_category {
  margin: 0px 5px 0px 0px;
}
.searched_categories .category,
.selected_cats .category {
  padding: 1px 5px 3px 5px !important;
}
.category_selector {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.category_selector .basic-multi-select {
  padding-left: 8px;
  margin-top: 3px;
  margin-right: 12px;
  flex-grow: 2;
}

.selected_cats {
  display: flex;
  justify-content: flex-start;
}
.selected_cats > .categories {
  margin-top: 5px;
}

.post_similar_titles {
  padding: 10px;
  color: rgb(218, 218, 218);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.post_similar_titles a {
  color: #e69244c6;
  margin-top: 5px;
}

.post_editor_rich_content_modal{
  height: 305px;
}

.post_editor_rich_content_modal .post_main_body{
  padding-bottom: 10px;
}

.post_editor_rich_content{
  padding-bottom: 15px;
}

.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  color: black;
}

.quill{
  position: relative;
}

.ql-container {
  border: none !important;
}

.ql-toolbar.ql-snow{
  background-color: #6b6b6b34;
  padding: 5px;
  border: none !important;
  position: absolute;
  bottom: 0;
}

.ql-editor,
.ql-editor.ql-blank{
  padding: 5px !important;
}

.ql-editor.ql-blank::before{
  color:rgb(218, 218, 218);
  font-style: normal;
  font-size: 13px;
  font-weight: 600;
}
.ql-editor.ql-blank:hover::before,
.ql-editor.ql-blank:active::before{
  color: #5f5f5f;
}

@media (max-width: 600px){
  .submit_post_editor_modal button {
    max-width: 123px;
    font-size: 10.5px;
  }



  .submit_post_editor_modal_toogle_button{
    display: none;
  }
}

@media (max-width: 884px) {
  .post_brief_inner{
    display: unset;
  }

  .post_main_status_label,
  .post_main_version_label{
    display: unset;
  }

  .disclaimer_short {
    display: none;
  }

  .disclaimer_full {
    display: unset;
  }

  .post_dashboard_main_modal .comment_panel_descriptor_di
  .post_dashboard_main .comment_panel_descriptor_div{
    flex-direction: column;
    
    
    .post_main_version {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background-color: #3f3e3e;

      width: 100%;
      padding: 0;
      margin-top: 10px;
      margin-left: 0;
      padding-top: 5px;
      padding-bottom: 5px;
      padding-right: 15px;

      .basic-multi-select{
        width: 100% !important;
        padding-left: 9px;
        padding-top: 8px;
      }
    }


    .post_main_content{
      background-color: #3f3e3e !important;
    }
  }

  .post_second_row{
    flex-direction: column !important;

    .post_main_version {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background-color: #3f3e3e;
      margin-right: -10px;
      margin-top: 10px;
      margin-left: -10px;
      padding-top: 5px;
      padding-bottom: 5px;

      .basic-multi-select{
        width: 100% !important;
        padding-top: 8px;
      }

      .MuiSvgIcon-root{
        margin-bottom: -8px;
      }
    }

    .post_main_question{
      margin-right: -10px;
    }
  }
}